import React from 'react';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import RoyaltyContentList from '../components/RoyaltyContentList';
import HomeLogos from '../components/HomeLogos';

export default () => (
  <Layout current="scens" subCurrent="royalty">
    <CommonPageBanner
      title="会员营销管理"
      description="连接所有的会员触点和平台，构建一体化会员系统，实现跨平台的会员私域流量运营模式。"
      imgName="royalty"
    />
    <RoyaltyContentList />
    <HomeLogos />
  </Layout>
);
