import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import TitledVerticalBoxes from './TitledVerticalBoxes';
import Scen8 from '../images/scen-8.svg';
import VerticalBox from './VerticalBox';

const SCEN_DATA = [
  {
    id: 'scrm-1',
    iconIndex: 51,
    description: '会员内容推送',
  },
  {
    id: 'scrm-2',
    iconIndex: 17,
    description: '会员忠诚度管理',
  },
  {
    id: 'scrm-3',
    iconIndex: 16,
    description: '新会员促进首购',
  },
  {
    id: 'scrm-4',
    iconIndex: 24,
    description: '会员积分和权益',
  },
  {
    id: 'scrm-5',
    iconIndex: 52,
    description: '会员等级管理',
  },
  {
    id: 'scrm-6',
    iconIndex: 42,
    description: '营销卡券管理',
  },
  {
    id: 'scrm-7',
    iconIndex: 14,
    description: '异常会员订单分析',
  },
  {
    id: 'scrm-8',
    iconIndex: 8,
    description: '会员拉新留存促活',
  },
];

const TOP_DATA = [
  {
    id: 'top-1',
    iconIndex: 39,
    description: '微信会员',
  },
  {
    id: 'top-2',
    iconIndex: 53,
    description: '小程序会员',
  },
  {
    id: 'top-3',
    iconIndex: 54,
    description: '线下会员卡',
  },
  {
    id: 'top-4',
    iconIndex: 55,
    description: '第三方会员',
  },
  {
    id: 'top-5',
    iconIndex: 56,
    description: '联名会员',
  },
  {
    id: 'top-6',
    iconIndex: 57,
    description: '电商会员',
  },
  {
    id: 'top-7',
    iconIndex: 58,
    description: 'App会员',
  },
  {
    id: 'top-8',
    iconIndex: 59,
    description: '网站会员',
  },
  {
    id: 'top-9',
    iconIndex: 60,
    description: '阿里会员通',
  },
  {
    id: 'top-10',
    iconIndex: 61,
    description: '呼叫中心',
  },
];

const TitledFiveInRowBoxes = ({ children }) => {
  return (
    <section id="titled-five-in-row-boxes" className="titled-five-in-row-boxes">
      <Container>
        <div className="title-wrapper">
          <h3>全渠道会员统一管理</h3>
          <h4>
            企业拥有多个渠道的会员数据已经成为常态，为了避免营销活动对会员的重复触达，实现统一的会员营销策略，有必要对分散在全渠道的会员数据进行统一管理。
          </h4>
        </div>
        <Row className="icon-boxes" gutter={{ md: 30, sm: 15, xs: 15 }}>
          {children}
        </Row>
      </Container>
    </section>
  );
};

TitledFiveInRowBoxes.propTypes = {
  children: PropTypes.node,
};

export default () => {
  return (
    <div className="content-list-wrapper royalty">
      <TitledFiveInRowBoxes>
        {TOP_DATA.map((item) => (
          <Col className="icon-text-col" key={item.id}>
            <VerticalBox {...item} smLayout="in-row" />
          </Col>
        ))}
      </TitledFiveInRowBoxes>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Scen8} imgWidth={445} imgHeight={325} title="构建完善的会员中台体系" imageSize={10}>
            <ImageTextDescription
              label="会员数据管理平台"
              content="建立会员CDP数据平台，对数据进行清洗、整合、分析和洞察"
            />
            <ImageTextDescription
              label="会员标签体系"
              content="建立体系化的标签体系，提供完善的会员标签解决方案和顾问服务"
            />
            <ImageTextDescription
              label="会员圈群分层"
              content="智能化会员分层分组，并可支持跨平台和跨渠道的会员圈群运营"
            />
            <ImageTextDescription
              label="精准会员画像"
              content="通过标签、来源、事件、活跃度等指标形成精准360°会员画像"
            />
            <ImageTextDescription
              label="会员旅程设计"
              content="基于会员的标签和分层，分别设置不同的会员生命周期旅程策略"
            />
            <ImageTextDescription
              label="会员自动化营销"
              content="Always-on自动化营销管理，可实现超大规模的个性化会员营销"
            />
          </ImageText>
        </Container>
      </section>
      <TitledVerticalBoxes title="丰富的会员营销场景" shadowed noBottom>
        {SCEN_DATA.map((item) => (
          <Col lg={6} md={12} sm={12} xs={12} className="icon-text-col" key={item.id}>
            <VerticalBox {...item} />
          </Col>
        ))}
      </TitledVerticalBoxes>
    </div>
  );
};
